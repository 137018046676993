const PathConstants = {
    HOME: "/",
    // other pages's paths...
    REPAY: "repay",
    JOIN: "join",
    BECOMEFITNESS: "become-fitness",
    PRIVACY_POLICY: "privacy-policy",
    TERMS_OF_SERVICE: "terms-of-service",
    EULA: "eula",
};

export default PathConstants;
