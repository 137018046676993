import { Link } from "react-router-dom";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="row">
        <div className="large-8 medium-6 cell">
          <p className="copyright">© {new Date().getFullYear()} FitnessRepay, Inc. All rights reserved.</p>
        </div>
        <div className="large-4 medium-6 cell">
          <ul className="footer-links">
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/eula">EULA</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;